import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import "./App.css";
import Homepage from "./screen/Homepage";
import Signuppage from "./screen/Signuppage";
import { FormProvider } from "./utilities/Formcontext";
import Signuppage2 from "./screen/Signuppage2";
import Signuppage3 from "./screen/Signuppage3";
import Signuppage4 from "./screen/Signuppage4";
import Booktaskpage from "./screen/Booktaskpage";
import { BookProvider } from "./utilities/Bookcontext";
import Booktaskpage1 from "./screen/Booktaskpage1";
import Booktaskpage2 from "./screen/Booktaskpage2";
import Booktaskpage3 from "./screen/Booktaskpage3";
import Booktaskpage4 from "./screen/Booktaskpage4";
import Mytaskpage from "./screen/Mytaskpage";
import Schedulepage from "./screen/Schedulepage";
import Completedpage from "./screen/Completedpage";
import { AuthProvider, useAuth } from "./utilities/Auth";

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();
  return currentUser ? children : <Navigate to='/signup_step_1' replace />;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/signup_step_1' element={<Signuppage />} />
          <Route path='/signup_step_2' element={<Signuppage2 />} />
          <Route path='/signup_step_4' element={<Signuppage4 />} />
          <Route
            path='/book_task'
            element={
              <ProtectedRoute>
                <Booktaskpage />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path='/book_tasks_step_1'
            element={
              <ProtectedRoute>
                <Booktaskpage1 />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path='/book_tasks_step_2'
            element={
              <ProtectedRoute>
                <Booktaskpage2 />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path='/book_tasks_step_3'
            element={
              <ProtectedRoute>
                <Booktaskpage3 />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path='/book_tasks_step_4'
            element={
              <ProtectedRoute>
                <Booktaskpage4 />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path='/my_tasks'
            element={
              <ProtectedRoute>
                <Mytaskpage />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path='/scheduled_task'
            element={
              <ProtectedRoute>
                <Schedulepage />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path='/completed_task'
            element={
              <ProtectedRoute>
                <Completedpage />{" "}
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
