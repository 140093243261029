import "./Signuppage.css";
import facebookicon from "../assets/facebookicon.svg";
import appleicon from "../assets/appleicon.svg";
import googleicon from "../assets/googleicon.svg";
import { useNavigate } from "react-router-dom";
import {
  auth,
  db,
  googleProvider,
  ref,
  sendSignInLinkToEmail,
  set,
  signInWithPopup,
} from "../utilities/firebaseConfig";

const Signuppage = () => {
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      await storeUser(result.user);
      navigate("/signup_step_2");
    } catch (error) {
      console.error(error);
    }
  };

  // const handleFacebookSignIn = async () => {
  //   try {
  //     const result = await signInWithPopup(auth, facebookProvider);
  //     await storeUser(result.user);
  //     navigate("/phone-verification");
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handlePasswordlessSignIn = async () => {
    const email = prompt("Enter your email:");
    const actionCodeSettings = {
      url: "http://localhost:3000/signin",
      handleCodeInApp: true,
    };
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    window.localStorage.setItem("emailForSignIn", email);
    alert("Check your email for a sign-in link.");
  };

  const storeUser = async (user) => {
    await set(ref(db, `users/${user.uid}`), {
      email: user.email,
      role: "user",
    });
  };
  return (
    <div className='signuppage'>
      <div className='signuppage-container'>
        <div className='signuppage-left'>
          <div className='signuppage-lcnt'>
            <div className='signupwriteup'>
              <h2 className='signuptitle'>Create a free account</h2>
              <p className='signupparagraph'>
                Sign up free and connect with trusted professionals in minutes
              </p>
            </div>

            <div className='signupemailform'>
              <input type='email' name='email' placeholder='sample@email.com' />
              <button className='bigbtn'>Continue</button>
            </div>

            <div className='ordivider'>
              <div className='ordividerr'></div>
              <p>OR</p>
              <div className='ordividerr'></div>
            </div>

            <div className='signupauthbtn'>
              <button>
                <img src={facebookicon} alt='' />
                <label>Continue with Facebook</label>
              </button>
              <button onClick={handleGoogleSignIn}>
                <img src={googleicon} alt='' />
                <label>Continue with Google</label>
              </button>
              <button>
                <img src={appleicon} alt='' />
                <label>Continue with Apple</label>
              </button>
            </div>
          </div>
        </div>
        <div className='signuppage-right'></div>
      </div>
    </div>
  );
};

export default Signuppage;
