import React, { useContext, useState } from "react";
import { FormContext } from "../utilities/Formcontext";
import { Link, useNavigate } from "react-router-dom";
import { auth, db, ref, set } from "../utilities/firebaseConfig";

const Signuppage4 = () => {
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [country, setCountry] = useState("Nigeria");

  const navigate = useNavigate();

  const saveDetails = async () => {
    if (!address || !city || !stateProvince || !country) {
      alert("Please fill all fields.");
      return;
    }

    const userId = auth.currentUser.uid;

    // Store details in Firebase Realtime Database
    await set(ref(db, `users/${userId}`), {
      firstName: auth.currentUser.firstName,
      lastName: auth.currentUser.lastName,
      mobile: auth.currentUser.mobile,
      email: auth.currentUser.email,
      address: address,
      city: city,
      stateProvince: stateProvince,
      country: country,
      role: "user",
    });

    navigate("/book_task");
  };

  return (
    <div className='signuppage'>
      <div className='signuppage-container'>
        <div className='signuppage-left'>
          <div className='signuppage-lcnt'>
            <div className='signupwriteup signup4typo'>
              <p className='acts4typo'>Account Setup</p>
              <h2 className='signuptitle'>
                Enter your residential address to complete sign up
              </h2>
            </div>

            <div className='signupemailform'>
              <div className='inputform'>
                <label htmlFor=''>Address</label>
                <input
                  type='text'
                  name='address'
                  placeholder='Enter your address'
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className='inputform'>
                <label htmlFor=''>City</label>
                <input
                  type='text'
                  name='city'
                  placeholder='Enter city here'
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className='inputform'>
                <label htmlFor=''>State/province/region *</label>
                <input
                  type='text'
                  name='state'
                  placeholder='Enter state here'
                  value={stateProvince}
                  onChange={(e) => setStateProvince(e.target.value)}
                />
              </div>
              <div className='inputform'>
                <label htmlFor=''>Country</label>
                <div className='inputformcountry'>
                  <p className='countryname'>Nigeria</p>
                </div>
              </div>
            </div>

            <button className='bigbtn' onClick={saveDetails}>
              Complete Sign up
            </button>
          </div>
        </div>
        <div className='signuppage-right'></div>
      </div>
    </div>
  );
};

export default Signuppage4;
