import React, { useContext, useState } from "react";
import { FormContext } from "../utilities/Formcontext";
import { useNavigate } from "react-router-dom";
import { auth, db, ref, set } from "../utilities/firebaseConfig";

const Signuppage2 = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const navigate = useNavigate();

  const saveDetails = async () => {
    if (!firstName || !lastName || !mobile) {
      alert("Please fill all fields.");
      return;
    }

    const userId = auth.currentUser.uid;

    // Store details in Firebase Realtime Database
    await set(ref(db, `users/${userId}`), {
      firstName,
      lastName,
      mobile,
      email: auth.currentUser.email,
      role: "user",
    });

    // Navigate to phone verification with mobile number as state
    navigate("/signup_step_4");
  };

  return (
    <div className='signuppage'>
      <div className='signuppage-container'>
        <div className='signuppage-left'>
          <div className='signuppage-lcnt'>
            <div className='signupwriteup'>
              <h2 className='signuptitle'>Create a free account</h2>
              <p className='signupparagraph signupp2'>
                You’re almost there. Create your new account for
                <span></span>
                by completing these details
              </p>
            </div>

            <div className='signupemailform'>
              <div className='inputform'>
                <label htmlFor=''>First Name</label>
                <input
                  type='text'
                  name='firstName'
                  placeholder='Enter your first name'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className='inputform'>
                <label htmlFor=''>Last Name</label>
                <input
                  type='text'
                  name='lastName'
                  placeholder='Enter your last name'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              {/* <div className='inputform'>
                <label htmlFor=''>Password</label>
                <input
                  type='password'
                  name='password'
                  placeholder='Enter your password'
                />
              </div> */}
              <div className='inputform'>
                <label htmlFor=''>Mobile Number</label>
                <div className='phoneinput'>
                  <label htmlFor=''>+234</label>
                  <input
                    type='phone'
                    name='phone'
                    placeholder='Enter mobile number here'
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <p className='signupagreement'>
              By clicking on Continue you agree to the{" "}
              <span>Privacy policy</span> , <span>Terms </span>and{" "}
              <span>Conditions</span> of Handyman
            </p>
            <button className='bigbtn' onClick={saveDetails}>
              Continue
            </button>
          </div>
        </div>
        <div className='signuppage-right'></div>
      </div>
    </div>
  );
};

export default Signuppage2;
